@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap);
/* Global styles */


/* Remove ugly focus outline */
.slick-slide *:focus {
  outline: none !important;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 40% !important;
  width: auto !important;
  height: auto !important;
}

.slick-arrow::before {
  content: none !important;
}

.slick-disabled {
  opacity: 0.5;
}

.slick-prev {
  left: -70px !important;
}

.slick-next {
  right: -20px !important;
}

.slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar:hover {
  display: flex;
}
/* Track */
::-webkit-scrollbar-track {
  background: #eef2f6;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #98aeb6;
  border-radius: 10px;
}

/* Material UI Autocomplete */

.MuiAutocomplete-inputRoot {
  padding: 12px 32px 12px 10px !important;
}
.MuiAutocomplete-input {
  padding: 0 !important;
}

.MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator {
  color: #24a3ab;
}

.content {
  padding: 25px 100px;
}

.grid {
  display: grid;
  grid-template-columns: 10% 90%;
}

.grid-middle {
  display: grid;
  grid-template-columns: 40% 60%;
}

.tab-content {
  padding: 24px 0;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-csv {
  text-decoration: none;
}

.inputs {
  padding: 10px;
}

.paper {
  background-color: white !important;
}

