.content {
  padding: 25px 100px;
}

.grid {
  display: grid;
  grid-template-columns: 10% 90%;
}

.grid-middle {
  display: grid;
  grid-template-columns: 40% 60%;
}

.tab-content {
  padding: 24px 0;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-csv {
  text-decoration: none;
}

.inputs {
  padding: 10px;
}

.paper {
  background-color: white !important;
}
